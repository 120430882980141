




import { Component, Vue } from "vue-property-decorator";

@Component
export default class GetUserInfo extends Vue {
  mounted(): void {
    // 获取用户 openid
    if (Object.prototype.hasOwnProperty.call(this.$route.query, "code")) {
      this.$store.commit("setOpenId", this.$route.query.code);
    }
    const redirect = localStorage.getItem("entrancePath");
    const defaultRedirect = localStorage.getItem("defaultRedirect");
    console.log(redirect, defaultRedirect);
    this.$router.replace({ path: (redirect || defaultRedirect)! });
  }
}
